/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
    width: 100%;
}

:root {
    --btn-bg-hover-color:darkturquoise;
    --btn-bg:#0F296D;
    --btn-padding: 5px 20px;
    --btn-bg-color: var(--btn-bg);
    --btn-font-size: 14px;
    --btn-font-family: var(--font-2);
    --btn-box-shadow-one: var(--btn-bg) 0px 7px 15px -5px;
    --btn-box-shadow-two: var(--btn-bg) 0px 3px 10px -6px;
    --btn-hover-bg-color: var(--btn-bg);
    --btn-color: white;
    --btn-hover-color:var(--green-color);
    --btn-active-box-shadow: var(--btn-bg) 0px 7px 29px 0px;
    --btn-active-transform: translateY(10px);

    --font-1: Verdana, Geneva, Tahoma, sans-serif;
    --font-2: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    --font-3: 'Montserrat', sans-serif;



    --primary-color: rgb(27, 31, 41);
    --text-color: rgb(96, 97, 99);
    --secondary-color: rgb(255, 255, 255);
    --white-color: rgb(255, 255, 255);
    --para-color: rgb(96, 97, 99);
    --dark-color: rgb(27, 31, 41);
    --green-color: #0F296D;
    --downloading-color:#2590ce;
    /* --green-color:#435ebe; */
    /* --body-color:#f2f7ff; */
    --body-color: #F4F5F7;
    --footer-color: rgba(58, 58, 58, 0.5);
    --custom-border: 0;
    --light-color: #87CEEB;
    /* monospace
   */
    --font-mono: Consolas, "Andale Mono", Courier, "Courier New", monospace;
}

.dark-theme {
    --btn-bg-hover-color:#000000;
    --primary-color: rgb(237, 242, 252);
    --secondary-color: #000;
    --green-color: #87DB1C;
    --downloading-color:#abe262;
    --btn-bg-color: var(--btn-bg);
    --btn-bg:#87DB1C;
    --btn-hover-color:var(--green-color);
    --body-color: #fff;
    --custom-border: 1px solid rgb(223, 223, 223);
    --footer-color: rgb(255, 255, 255);
    --light-color: #E6E6E6;
}

.bxs-moon {
    color: var(--dark-color);
    font-size: 40px;
}

.bxs-sun {
    color: var(--green-color);
    font-size: 40px;
}

table .invalid-column{
    border: 2px solid red !important;
}